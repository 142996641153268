
.hero-container{
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0% 5% 0% 5%;
    align-items: center;
    height: 50vh;
    width: 100%;
}

.hero-text-container{
    text-align: center;
    display:block;
    font-size: 60px;
    padding: 50px;
    border-radius: 2%;
    /* border: 2px solid black; */
}

.hero-intro-text{
    
    font-size: 50px;
    color: #404040;
    font-family: 'Montserrat', sans-serif;
    padding-left: 2px;
}

.hero-name-text{
    font-size: 100px;
    display: block;
    font-family: Apercu;
}

.underline{
    text-decoration: underline;
    text-decoration-color: #404040;
    text-decoration-thickness:5px;
}


.hero-blurb-text{
    color: 	#404040;
    font-size: 15px;
}

.hero-bio{
    text-align: center;
    color: 	#404040;
    font-size: 15px;
    padding-left: 8px;
}

.hero_image{
    display: block;
    position: relative;
    height: 500px;
    margin-top: 10%;
}

mark {
    background-color: #404040;
    color: #FFFF;
    font-weight: bold;
    padding-left:10px;
    padding-right:8px;
  }

  @media (min-width: 768px) {
    .non-mobile-image {
      display: block; /* or any other styles you want */
    }
  }