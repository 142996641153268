.elementToFadeInAndOut {
    opacity: 1;
    animation:  fade 0.5s linear forwards;
}


/* @keyframes fade {
    from {
        opacity: 0;
        transform: translateY(40px);
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
} */
