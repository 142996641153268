.quote-container{
    font-size: 50px;
    
}

.quote-highlight{
    font-size: 200px;
}

.quote-author{
    font-size: 80px;
}

.loader{
    justify-self: center;
    margin:auto;
    width: 200px;
}

.container {
    display: inline-block;
    font-family: 'Apercu';
}

.fade-up {
    opacity: 0;
    animation: fadeUp 1s ease-in-out forwards;
  }
  
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translateY(50%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }